// Reset browser settings
@import '~normalize.css/normalize';
@import "theme/variables";
@import "styles/button";
@import 'theme/bootstrap/bootstrap-custom-grid';
// Add material colors for white label
@import 'theme';

.mat-form-field {
  width: 100%;
}

* { margin: 0; padding: 0; box-sizing: border-box;  }

html {
  font-size: 16px;
}

.book-shelf {
  .mat-paginator-page-size {
    display: none;
  }
  .mat-paginator {
      display: flex;
      justify-content: flex-end;
      width: 100%;
  }
}

mat-header-row {
  min-height: 52px !important;
}

mat-row, mat-footer-row {
  min-height: 44px !important;

    .material-icons {
      font-size: 20px;
  }
}

.session_wrap .mat-icon {
  cursor: pointer;
}
.session_wrap{
  mat-header-cell.mat-header-cell.mat-column-edit-button,
  mat-cell.mat-cell.cdk-cell.mat-column-edit-button {
    max-width: 60px !important;
    width: 100%;
  }

  mat-header-cell.mat-header-cell.mat-column-assign-student,
  mat-cell.mat-cell.cdk-cell.mat-column-assign-student {
    max-width: 60px;
    width: 100%;
  }
}


:focus {
  outline: none;
}

ion-app {

  ion-content {
    // height: 100vh;
    height: calc(100vh - 64px);
    .ion-content-wrap {
      position: relative;
      height: calc(100vh - 56px);

      @include --custom-min(600) {
        height: calc(100vh - 64px);
      }
    }
  }

  &.tablet-frame {
    width: 1280px;
    height: 725px;
    outline: 30px solid black;
    position: relative;
    display: block;
    margin: 80px auto;
    overflow: hidden;
    z-index: 1;

    mat-toolbar {
      position: sticky;
      top: 0;
    }

    ion-content {
        height: calc(725px - 56px + 15px);
  
        @include --custom-min(600) {
          height: calc(725px - 64px + 15px);
        }
    }

    .single_game_wrap {
        max-height: 725px;
    }

    .ion-content-wrap {
      overflow-y: auto;
      height: 100%;
    }
  }

  ion-content {
    height: calc(100vh - 56px);
  
    @include --custom-min(600) {
      height: calc(100vh - 64px);
    }
  }

  &.is-app  {
    mat-toolbar {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1;
      // Notch fix
      padding-top: constant(safe-area-inset-top);
      padding-top: env(safe-area-inset-top);
    }
    mat-drawer-container {
      position: inherit;
      height: 100vh;

      mat-drawer {
          position: fixed;
      }
    }

    ion-content {
      height: 100vh;
    }

    .ion-content-wrap {
      margin-top: 56px;

      @include --custom-min(600) {
        margin-top: 64px;
      }
    }
  }

  // Lenovo tablet
  // &.android-app {
  //   ion-content {
  //     height: calc(720px - 56px);
  
  //     @include --custom-min(600) {
  //       height: calc(720px - 64px);
  //     }
  //   }
  // }
}

// Statusbar ios fix
// ion-app {
//   &.ios {
//     mat-toolbar {
//       padding-top: 20px;
//     }

//     ion-content {
//       height: 100vh;
    
//       @include --custom-min(600) {
//         height: 100vh;
//       }
//     }

//     .ion-content-wrap {
//       margin-top: 76px;

//       @include --custom-min(600) {
//         margin-top: 84px;
//       }
//     }
//   }
// }

.container,
.container-fluid {
  // height: 100%;
}

//Game styles
app-game-connect-four {
  .board{
    width: 700px;
    height: 600px;
    background: #00658F;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: 0px 10px 43px -16px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 10px 43px -16px rgba(0,0,0,0.75);
    box-shadow: 0px 10px 43px -16px rgba(0,0,0,0.75);
  }

  .restart-game {
    position: relativ;
    right: 5px;
    top: calc(50% - 45px);

    @include --custom-min(999) {
      position: absolute;
    }
  }

  .game{
    position: relative;
    width: 700px;
    margin: 0px auto 60px;
  }

  .your_color{
    position: absolute;
    top: -100px;
    margin-left: 5px;
    width: 90px;
    height: 90px;
    -webkit-border-radius: 45px;
    -moz-border-radius: 45px;
    border-radius: 45px;
    background: #000;
    opacity: 0;
  }

  .your_color.show{
    opacity: 1;
  }

  .opponent_color{
    position: absolute;
    top: -100px;
    margin-left: 5px;
    width: 90px;
    height: 90px;
    -webkit-border-radius: 45px;
    -moz-border-radius: 45px;
    border-radius: 45px;
    background: #000;
    opacity: 0;
  }

  .opponent_color.show{
    opacity: .3;
  }

  .board .overlay{
    position: absolute;
    z-index: 100;
    left: 0;
    top: 0;
    pointer-events: none;
    -webkit-filter: drop-shadow( 5px 5px 5px rgba(0,0,0,0.1));
    filter: drop-shadow( 5px 5px 5px rgba(0,0,0,0.1));
  }

  .cols{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10;
  }

  .cols > .col{
    position: relative;
    float: left;
    width: 100px;
    height: 100%;
  }

  @-moz-keyframes blink {0%{opacity:1;} 50%{opacity:.5;} 100%{opacity:1;}}
  @-webkit-keyframes blink {0%{opacity:1;} 50%{opacity:.5;} 100%{opacity:1;}}
  @-ms-keyframes blink {0%{opacity:1;} 50%{opacity:.5;} 100%{opacity:1;}}
  @keyframes blink {0%{opacity:1;} 50%{opacity:.5;} 100%{opacity:1;}}
  .coin.winner_coin{
    -moz-transition:all 1s ease-in-out;
    -webkit-transition:all 1s ease-in-out;
    -o-transition:all 1s ease-in-out;
    -ms-transition:all 1s ease-in-out;
    transition:all 1s ease-in-out;
    -moz-animation:blink normal 1s infinite ease-in-out;
    -webkit-animation:blink normal 1s infinite ease-in-out;
    -ms-animation:blink normal 1s infinite ease-in-out;
    animation:blink normal 1s infinite ease-in-out;
  }

  .coin{
    position: absolute;
    left: 5px;
    margin-top: 5px;
    width: 90px;
    height: 90px;
    -webkit-border-radius: 45px;
    -moz-border-radius: 45px;
    border-radius: 45px;
  }

  .red {
    background: mat-color($theme-accent, main);
  }
  .yellow {
    background: mat-color($theme-primary, main);
  }
}

.hidden{
  display: none;
}

/* overlay with popups */

.underlay{
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,.5);
  z-index: 1000;
}

.popover{
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -200px;
  margin-top: -150px;
  box-sizing: border-box;
  padding: 10px;
  height: 130px;
  width: 400px;
  background: #f2f2f2;
  z-index: 10000;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 10px 43px -16px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 10px 43px -16px rgba(0,0,0,0.75);
  box-shadow: 0px 10px 43px -16px rgba(0,0,0,0.75);
}

.popover h2{
  font-size: 24px;
  padding: 0;
  margin: 0;
  line-height: 1.5em;
}

.popover p{
  margin: 0;
  padding: 0;
  line-height: 2em;
}

.popover input{
  position: absolute;
  bottom: 10px;
  width: 380px;
  line-height: 2em;
  font-size: 16px;
  box-sizing: border-box;
  padding: 0 10px;
  border: 1px solid #333;
  outline: none;
}

.status{
  position: absolute;
  bottom: -40px;
  width: 100%;
  text-align: center;
  line-height: 40px;
  font-size: 20px;
  color: #fff;
  background-color: #e64;
  border-radius: 0 0 10px 10px;
}

@media only screen and (max-device-width: 767px) {
  .game {
    margin: 120px 0;
    zoom: 138% !important;
  }
}

.ion-page {
  background: #fff !important;
}

// Login styles

.login {
  .login_wrap{
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
}

.reset-password {
    .reset_wrap {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;

        .mat-button.size-big {
          max-width: unset;
        }
    }
}

form {
  background: transparent;
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column;
  min-width: 300px;
}

.mat-button,
.mat-flat-button {

  &.size-large {
    font-size: 22px;
    line-height: 22px;
    margin: 8px;
    border: 1px solid;
    padding: 10px;
    min-width: 355px;
    max-width: 355px;
    width: 100%;
  
    mat-icon {
        margin-right: 5px;
        font-size: 26px;
        line-height: 20px;
    }
  }

  &.size-big {
    font-size: 22px;
    line-height: 22px;
    margin: 8px;
    border: 1px solid;
    padding: 6px;
    min-width: 230px;
    max-width: 230px;
    width: 100%;
  
    mat-icon {
        margin-right: 5px;
        font-size: 26px;
        line-height: 20px;
    }
  }

  &.size-small {
    font-size: 14px;
    line-height: 28px;
    margin: 10px;
    border: 1px solid;
    padding: 5px;
    min-width: 170px;
    max-width: 170px;
    width: 100%;

    mat-icon {
        margin-right: 5px;
        font-size: 20px;
        line-height: 22px;
    }
  }
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: #e5351f;
}

// Dashboard styles

.dashboard {

  .dashboard_wrap{
    height: 100%;
    display: flex;

    .dashboard_navigation {
      display: flex;
      flex-flow: column;
      width: 100%;
      height: calc(100vh - 64px);
      background: #ccc;

      mat-list-option {
        &.mat-list-item {
          border-top: 1px solid;

          &:last-child {
            border-bottom: 1px solid;
          }

          &.mat-list-single-selected-option {
              background-color: white;
          }
        }
      }
    }
    .instructionVideo_wrap {
        margin-left: 125px;
    }
    .button-group {
      margin-left: -125px;
    }
  }
}

.button-group {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-top: 90px;

  @include --custom-min(999) {
    margin-top: 0px;
  }

  &--aside {
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: center;
  }
} 

// video component
.video-container,
.record-container {
  display: flex;
  position: relative;
}

.video-elements-wrap {
  position: relative;
  /*display: flex;
  flex-flow: column;*/
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  /* grid-template-rows: 1fr 1fr 1fr; */
  gap: 0px 0px;

  .main-video {
    position: relative;
    width: 100%;
    display: grid;
    grid-area: 1 / 1 / 4 / 5;
    background-color: black;
  }

  .top-video {
    /*position: absolute;
    top: 20px;
    left: 20px;*/
    display: grid;
    grid-area: 1 / 1 / 2 / 2;
    margin: 15px;
    max-width: 100px;
    z-index: 2;
    border: 3px solid white;
    background-color: black;
    transform: scaleX(-1);
  }
  .video-element-play {
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: red;
  }
}

app-video-call {
  width: 100%;
}

.video-drag {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
}

.record-wrap {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
}

.cdk-drag.video-drag {
  z-index: 999;
  background: rgba(255,255,255,.5);
}

.container-wrap {
  display: flex;
  flex-flow: row;
}

// tabs
mat-tab-group[vertical] .mat-tab-labels {
  display: flex;
  flex-direction: column!important;
}

mat-tab-group[vertical] {
  display: flex;
  flex-direction: row!important;
}

/*.mentor_bg .video-elements-wrap {
  border: 5px solid mat-color($theme-primary, lighter);
}

.child_bg .video-elements-wrap {
  border: 5px solid mat-color($theme-accent, lighter);
}*/

// metamorphose

app-metamorphose {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.mentor_bg app-metamorphose video {
  width: 200px;
}

// book

.epub-wrap {
  background-color: white;
  margin-bottom: 0px;
}

.book-navbar {
  display: flex;
  flex-flow: row;
  justify-content: center;
  margin-bottom: 10px;
  background: white;
}

// pdf

.pdf-navbar {
  display: flex;
  flex-flow: row;
  justify-content: center;
  background: #eee;

  .page-zoom {
      display: flex;
      margin-left: auto;
  }

  .page-navigation {
    align-items: center;
    display: flex;
    margin-left: auto;
    margin-right: 10px;
  
    input {
        padding: 5px;
        margin: 0 5px;
        max-width: 55px;
        text-align: center;
    }
  }
}


.hidden_nav {
  visibility: hidden;
}

// record timer
.record-timer-bar {
  position: relative;
  margin: 30px 0;
  display: flex;
  align-items: center;

  .progressbar-0-indicator,
  .progressbar-25-indicator,
  .progressbar-50-indicator,
  .progressbar-75-indicator,
  .progressbar-100-indicator {
    display: block;
    position: absolute;
    background-color: black;
    width: 1px;
    z-index: 1;
  }

  .progressbar-0-indicator {
    height: 20px;
    left: 0;

    span {
      position: absolute;
      margin-top: 25px;
      margin-left: -3px;
    }
  }

  .progressbar-25-indicator {
    height: 14px;
    left: 25%;
  }

  .progressbar-50-indicator {
    height: 20px;
    left: 50%;


    span {
        position: absolute;
        margin-top: 25px;
        right: -18px;
    }
  }

  .progressbar-75-indicator {
    height: 14px;
    left: 75%;
  }

  .progressbar-100-indicator {
    height: 20px;
    right: 0;

    span {
      position: absolute;
      margin-top: 25px;
      right: -3px;
    }
  }
}

.logo-wrap {
  display: block;
  margin-right: 15px;
  cursor: pointer;
}

.logo-title {
  display: flex;

  span.app-version {
      font-size: 9px;
      margin-left: 10px;
  }
}

mat-toolbar-row {
  .toolbar-spacer {
      flex: 1 1 auto;
  }

  .center-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
  }

  .left-wrap {
      display: flex;

      .mat-icon-button {
        display: flex;
        justify-content: center;
        align-self: center;
        margin-right: 15px;
      }
  }

  .right-wrap {
      display: flex;
  }

  span.logo-wrap img {
    height: 55px;
    position: relative;
    width: auto;
    display: block;
  }

  .mat-button-base {
      padding: 0 14px;
      margin-left: 5px;
  }

  .icon-with-text mat-icon {
    padding: 0 0px 0 10px;
    margin-left: -10px;
  }

  .icon-with-text mat-icon {
      margin-right: 15px;
  }
}

.mat-snack-bar-container {
    background-color: mat-color($theme-primary, main);
    color: #000000;

    &.snack-error {
      background-color: mat-color($theme-accent, main);
      color: #ffffff;
    }
}

.cdk-overlay-container {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // background: rgb(0 0 0 / 69%);

//   .cdk-overlay-pane {
//     min-width: 500px;
//     width: 100%;
//     background: #fff;
//     justify-content: center;
// }

  .mat-selection-list {
    padding: 15px 0px;
  }
  .mat-list-item {
    border: 1px solid;
    margin: 5px;
  }
  .mat-list-single-selected-option {
    background: #9cb929;
  }
}

.student_container {
    height: 500px;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  h1 {
      font-size: 60px;
      color: #e5351f;
      line-height: 68px;
  }
}

.online-status {
  width: 10px;
  height: 10px;
  background-color: red;
  position: absolute;
  right: 10px;
  border-radius: 50%;
  top: calc(50% - 5px);

  &--is-online {
    background-color: green;
  }
}

// Mat Dialog
.mat-dialog-container {
  padding: 45px !important;
  min-width: 300px;
}

.mat-dialog-title,
.mat-dialog-content {
  text-align: center;
}

.mat-dialog-actions {
  justify-content: center;
}

.mat-list-text mat-icon {
  font-size: 24px !important;
  position: absolute;
  right: 0;
  color:  mat-color($theme-accent, main);
}

// .single_game_wrap {
//   width: 100%;
//   height: 75%;
// }

.single_game_wrap {
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  left: 0;
  right: 0;

  .exit-game {
    position: absolute !important;
    right: 15px;
    top: 15px;
    z-index: 99;
  }
}

// canvas {
//     width: 1231px !important;
//     height: 578px !important;
// }

.dashboard-logo {
  margin-top: 30px;
  max-width: 175px;
  margin-bottom: 20px;
}

.progress-fullscreen {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(000,000,000,0.8);
  top: 0;
  left: 0;
  z-index: 9999999999999;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 10%;

  .loding-spinner-wrap {
    margin-top: -70px;
    margin-bottom: 30px;
  }

  .loading-spinner > div {
    background-color: white !important;
  }
}

// audio::-webkit-media-controls-mute-button { 
//   display: none !important; 
//   overflow: hidden;
// }
//  audio::-webkit-media-controls-volume-slider {
//    display: none !important;
// }

// audio::-webkit-media-controls-timeline {
//   display: none !important;
// }

// /* Removes the time stamp */
// audio::-webkit-media-controls-current-time-display {
//   display: none;
// }
// audio::-webkit-media-controls-time-remaining-display {
//   display: none;
// }

// audio {
//   width: 115px;
// }
mat-cell audio {
  height: 40px !important;
}

.text-center {
  text-align: center
}

.network_status_check {
  position: absolute;
  top: 64px;
  width: 100%;
  text-align: center;
  background: red;
  z-index: 999999;
  left: 0;
  right: 0;
  
  p {
      padding: 0px;
      margin: 2px;
      font-size: 11px;
      color: white;
  }
}

#epub-viewer {
  position: absolute;
  overflow: scroll;
  width: 820px;
  height: 650px;
}

img.mat-list-image {
  height: 47px;
  width: auto;
  display: flex;
  position: absolute;
  right: 0;
  top: 0px;
}

.align-center {
  text-align: center;
}

.book-shelf {
  mat-card {
    height: 100%;

    .mat-card-header-text {
        display: flex;
        width: 100%;
        margin: 0;
    }

    .mat-card-header .mat-card-title {
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
    }

    .mat-card-actions .mat-button {
        background-color: #eee;
    }

    .mat-card-image-wrap {
      //max-height: 200px;

      .mat-card-image {
          // height: 200px;
          // object-fit: cover;
          margin-top: 0 !important;
          //object-position: top;
      }

      .no-image {
          //height: 200px;
          background-color: #ccc;
          margin-left: -16px;
          margin-right: -16px;
      }
    }
  }
}

.cdk-overlay-container .book-shelf {
  &> div {
      margin-bottom: 35px;
  }
}

// GAME UNO Styles
app-game-uno {
  .game {
    width: 100%;
    height: 100%;
    max-height: 590px;
    overflow: hidden;
    display: flex;
    flex-flow: column;
    position: relative;

    @include --custom-min(999) {
      width: calc(100% - 55px);
    }

  }

  .restart-game {
    position: relativ;
    right: 5px;
    top: calc(50% - 45px);
    margin-top: 16px;
    @include --custom-min(999) {
      position: absolute;
    }
  }

  #uno-color-choice {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      bottom: 0;
      right: 0;
      background-color: rgba(255, 255, 255, 0.95);
      display: none;

      h3 {
        font-size: 30px;
        text-align: center;
      }
  }

  .color-choice-content {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      flex-flow: column;
  }

  .color-choice-wrap {
    width: 300px;
    height: 400px;
    display: flex;
    position: relative;
    flex-wrap: wrap;
    border: 10px solid black;
    border-radius: 50%;
    overflow: hidden;
    transform: rotate(25deg);

    &> div {
      width: 50%;
      height: 50%;
      display: flex;
      cursor: pointer;
    }

    .red {
      background-color: #ff5454;
    }

    .green {
      background-color: #00ac00;
    }

    .yellow {
      background-color: #ffab00;
    }

    .blue {
      background-color: #5454ff;
    }
  }

  .collection {
      margin: 0 !important;
  }

  .players {
    position: absolute;
    right: 0;
    z-index: 9;
  }

  .deck-wrapper {
    position: relative;
    padding-top: 30px;
    padding-bottom: 20px;
  }

  .game_board {
    position: relative;
    flex: 1 0 auto;
  }

  .none {
    display: none;
  }

  .board {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100%;
  }

  .board img {
    width: 170px !important;
    margin-top: 30px;
  }

  // Generate card mixin
  @mixin fan($count, $angle) {
    @for $i from 1 through $count {
      div:nth-child(#{$i}) {
        transform: translate(-50%, -50%) rotate(-$angle / 2 + $angle / 6 * $i);
      }
    }
  }

  .gamecard {
    margin: 0px;

    img {
      width: 89px;
      height: auto;
      margin: 10px;
    }

    // Card fan settings
    /*position: absolute;
    left: 50%;
    top: 50%;
    transform-origin: center 120%;*/
  }

  .card-deck {
    display: block;
  }
  
  #frame {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: auto;
    // Card fan settings
    /*height: 230px;
    // Generate card fan with mixin
    @include fan(30, 50deg);
    &:hover {
      @include fan(30, 60deg);
    }*/
  }

  .turn {
      transform: scale(1.1);
      color: #ecf0f1 !important;
      background-color: #2c3e50 !important;
  }

  .turn .badge{
      color: #ecf0f1 !important;
      background-color: #2c3e50 !important;
  }

  .deck {
    margin-top: 30px;
  }

  .badge {
    padding-right: 10px;
    display: flex;
    min-width: 30px;
    justify-content: flex-end;
  }

  .collection-item {
    padding: 5px;
    display: flex;
    flex-flow: row;

    &.current {
      font-weight: bold;
    }
  }

  select {
    display: block;
  }

  .col .waves-effect.waves-light.btn{
    background-color: #2c3e50 ;
  }

  .col .waves-effect.waves-light.btn:hover{
    background-color: #34495e ;
  }

  .sidenav-trigger{
    position: fixed;
    bottom: 0;
    left:  0;
    margin: 10px;
    padding: 5px;
    cursor: pointer;
    border-radius:  10px;
    z-index: 5;
  }

  .btn,
  .btn-flat,
  .btn-floating ,
  .btn-large{
    background-color: #2c3e50 !important;
    color: #ecf0f1 !important;
    padding: 5px;
    margin: 5px;
  }

  textarea{
    position: fixed !important;
    bottom: 0px !important;
    margin-bottom: 60px !important;
    background-color: white !important;
  }

  ul.sidenav{
    overflow-y: hidden;
  }

  .swal-title{
    font-size: 12px !important;
  }

  .swal2-popup.swal2-modal.swal2-show{
    padding: 10px !important;
    text-align: left;
  }

  .swal2-popup{
    width: auto !important;
  }

  .open-players{
    display: none;
    top:0;
    right: 0;
    z-index: 1;
  }

  .players ul .collection{
    z-index: 10;
    display: flex;
    flex-flow: column;
  }

  .players ul{
    display: block;
    list-style-type: none;
    padding: 10px;
  }

  /* responsive */

  @media screen and (max-width: 600px){
      .open-players{
          display: block;
      }
      .players ul{
          display: none;
      }
      .board img{
              width: 100px !important;
      }
      .item-slick img{
          width: 70px !important;
      }
  }
}

.mat-badge {
  position: relative;
  top: 6px;
  left: 6px;
  font-size: 11px;
}

[hidden] {
  display: none !important;
}

.book-shelf {
    .booklist_icons {
      display: flex;
      align-items: center;
    }

    .booklist_name {
      width: 100%;
    }
    
    .mat-list-text {
        display: flex;
        flex-flow: wrap-reverse;
    }

    .mat-list-text mat-icon {
      font-size: 24px !important;
      position: relative;
      right: 0;
      color:  mat-color($theme-accent, main);
      margin: 0 10px;
    }
    
}

.table-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f7f7f7;
  padding: 10px 15px 5px 15px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);

  .filter-area {
    width: 100%;

    .filter-wrap {
        display: flex;
        align-items: center;

        .mat-checkbox {
            margin-left: 15px;
        }

        mat-form-field.mat-form-field {
          margin-right: 40px;
      }
    }
  }
  .button-area {
    a {
      color: black;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;

      .mat-icon {
        margin-right: 5px;
      }
    }
  }
}

.iframe-overlay-pane {
  max-width: 1200px !important;

  .mat-dialog-container {
      padding: 0px !important;
  }
  .iframe-col {
    padding: 0;
  }

  &--video {
    max-width: 800px !important;
    // margin-left: 25%;
  }
}

.tandem-overlay-pane {
  max-width: 1200px !important;

  &--video {
    max-width: 800px !important;
    margin-left: 25%;
  }
}

.corner-info {
  position: absolute;
  right: 20px;
  top: 20px;
  display: flex;
  align-items: flex-end;
  flex-flow: column;

  .technical-support {
      display: flex;
      flex-direction: row;
      max-width: 250px;
      background-color: #eee;
      padding: 10px;
      margin-bottom: 10px;
      border: 1px solid;
      font-size: 12px;
      line-height: 16px;
      white-space: pre-line;

      mat-icon {
          margin-right: 10px;
      }
  }
}

// login page instructionVideo
.instructionVideo_wrap {
  display: flex;

  &--two-videos {
    display: flex;
    position: relative;
    left: 62px;
  }

  .instructionVideo {
    font-size: 12px;
    line-height: 0px;
    display: flex;
    align-items: center;
    position: relative;
    min-width: 125px;
    max-width: 125px;
    justify-content: center;

    &--alert {
      color: #e5351f;
    }
  }
}

.quiz-overlay-panel {
  max-height: 95vh;
}

.notification_button .mat-icon{
  font-size: 20px;
}

.notification_status {
  background: #ff0000;
  padding: 4px;
  position: absolute;
  right: 5px;
  top: 20px;
  border-radius: 50%;
}

.notification_menu {
  padding: 10px;
  max-height: 50vh !important;
  margin-top: 14px;
}

.close_overlay_wrap {
  position: fixed;
  max-width: 1200px;
  width: 100%;

  .close_overlay {
    right: 0;
    position: absolute;
    font-size: 33px;
    height: 33px;
    width: 33px;
    cursor: pointer;
    color: #fff;
    top: -33px;
  }
}

.close_lexicon_overlay_wrap {
  position: fixed;
  max-width: 450px;
  width: 100%;

  .close_lexicon_overlay {
    right: 0;
    position: absolute;
    font-size: 33px;
    height: 33px;
    width: 33px;
    cursor: pointer;
    color: #fff;
    top: -74px;
  }
}

.close_booklist_overlay_wrap {
  position: fixed;
  right: 144px;

  .close_booklist_overlay {
    right: 0;
    position: absolute;
    font-size: 33px;
    height: 33px;
    width: 33px;
    cursor: pointer;
    color: #fff;
    top: -90px;
  }
}

.close_gamelist_overlay_wrap {
  position: fixed;
  right: 122px;

  .close_gamelist_overlay {
    right: 0;
    position: absolute;
    font-size: 33px;
    height: 33px;
    width: 33px;
    cursor: pointer;
    color: #fff;
    top: -80px;
  }
}

button.chat_icon {
  margin-right: 18px;
  margin-top: 10px;
  background-color: #eeeeee;
  .mat-button-wrapper {
    font-size: 16px;
  }
}
.game-list {
  margin-left: -20px;

  @include --custom-min(800) {
    margin-left: 0px;
  }
  .metamorphose-header {

    h1 {
        background: green;
        color: #fff;
        border-radius: 50%;
        font-size: 15px;
        padding: 15px;
        width: 30px;
        height: 30px;
        position: absolute;
        top: 8px;
        right: -43px;
        display: flex;
        justify-content: center;
        align-items: center;
        
        @include --custom-min(999) {
          font-size: 29px;
          width: 62px;
          height: 62px;
          text-align: center;
          right: -15px;
        }
  
    }
  
    .metamorphose-earned {
        position: relative;
        right: 20px;
  
        img {
            width: 150px;
        }
    }
  }
  
  .game_selection_row{
    display: block;

    @include --custom-min(999) {
      display: flex;
    }
  }

  .game-button-wrap {
    display: flex;
    flex-flow: row-reverse;
    align-items: center;
    
    @include --custom-min(999) {
      flex-flow: column;
    }
  }
}

.balance-text {
  font-size: 1.5rem;

  @include --custom-min(999) {
    font-size: 2rem;
  }
}

.list-item-container{
  .view-book-btn{
    float: left;

    @include --custom-min(999) {
      float: right;
    }
  }
}

app-iframe {
  .container-fluid{
    height: 100%;
  }
}

.mat-drawer-inner-container {
  display: flex;
  flex-flow: column;
  padding: 20px;
}

mat-drawer-container {
  button {
    margin-bottom: 15px;
  }
}

.close_drawer {
  cursor: pointer;
  margin-bottom: 20px;
  text-align: right;

  mat-icon{
    cursor: pointer;
  }
}

.mentor_nav{
  display: none;

  @include --custom-min(1200) {
    display: block;
  }
}

.drawer_menu{
  display: block !important;

  @include --custom-min(1200) {
    display: none !important;
  }
}

.bookstore-overlay-panel {
  overflow: auto;
  max-width: 800px !important;
  margin-left: 25%;
  max-height: 550px;
}

.bookselection-overlay-panel{
  justify-content: center;
  
  .mat-dialog-container {
    max-width: 900px;
  }
}