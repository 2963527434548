.btn {
  display: inline-block;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  letter-spacing: 1px;
  cursor: pointer;
  text-align: center;
  outline: none;
  border: none;
  border-radius: 15px;
  margin-bottom: 2px;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;

  &.btn-block {
    display: block;
    width: 100%;
  }

  &.btn-sm {
    font-size: 1rem;
  }

  &.btn-primary {
    background-color: $primary;
    color: $white;

    &:hover {
      background-color: darken($primary, 5%);
    }
  }

  &.btn-danger {
    background-color: $danger;
    color: #fff;

    &:hover {
      background-color: darken($danger, 5%);
    }
  }

  &.btn-secondary {
    background-color: $secondary;
    color: #fff;

    &:hover {
      background-color: darken($secondary, 5%);
    }
  }
}

.action-buttons {
  .btn {
    margin: 1em 0;
  }
}

@media only screen and (min-width: $breakpoint-md) {
  .action-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .btn {
      margin: 0.625rem 2rem;
      flex: 1 0 33%;
    }
  }
}
