// Max layout width
$layout-max-width: 1440px !default;

/* Colors */
$primary: #aac939;
$secondary: #9d9d9d;
$danger: #b02127;

$black: #000;
$white: #fff;

/* Breakpoints */
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1400px;